<template>
	<b-card id="clickRateByClickers" v-if="$checkPermission('view-target-risk')">
		<b-card-title class="mb-4">
			<div class="title" :title="title">
				{{ title }}
				<font-awesome-icon id="clickRateByClickersTooltip" :icon="['fas', 'info-circle']" class="mr-1"/>
				<b-tooltip class="mt-2 mr-2" triggers="hover" right target="clickRateByClickersTooltip">
					<span v-html="$t('Analytics.Tooltips.clickRateByClickersInfo')"></span>
				</b-tooltip>
			</div>
			<b-col class="filter-container">
				<span v-html="breadCrumb" ref="breadCrumb"></span>
			</b-col>
			<b-spinner class="spinner" variant="primary" :label="$t('General.Loading')" v-if="inProgress"></b-spinner>
		</b-card-title>
		<b-row class="mt-2 mb-4 text-right">
			<b-col align-self="center" class="row-counter">{{$tc('General.Target', targetCount)}}</b-col>
			<b-col v-if="this.$checkPermission('view-target-details')">
				<label class="sr-only" for="target-search-input">{{ $t('General.TypeToSearch') }}</label>
				<b-form-input id="target-search-input" v-model="context.searchText" type="search" 
					:placeholder="$t('General.TypeToSearch')" @input="search"></b-form-input>
			</b-col>
		</b-row>
		<b-row class="mb-4">
			<b-col>
				<b-form-group label-size="md" label-cols-lg="6" label-cols="8" label-align-sm="right" :label="$t('General.Show')">
					<b-form-select class="mr-2" id="targetStatusFilter" size="sm" v-model="targetStatusFilter" :options="targetStatusOption" @change="refresh">
					</b-form-select>
				</b-form-group>
			</b-col>
		</b-row>
		<cg-analytics-table :data="data" @sortChanged="sort" variant="dawn"></cg-analytics-table>
	</b-card>
</template>

<script>
import AnalyticsTable from "@/components/tables/AnalyticsTable";
import companyService from "@/services/company.service";

export default {
	components: {
		"cg-analytics-table": AnalyticsTable
	},
	data() {
		return {
			title: this.$t('Analytics.ClickRateByTargets'),
			data: {},
			myself: "clickers",
			breadCrumb: "",
			inProgress: false,
			reportingEnabled: false,
			context: {
				searchText: "",
				sortBy: "click_rate",
				sortDesc: true
			},
			targetStatusFilter: null,
			targetStatusOption: []
		};
	},
	computed: {
		targetCount() {
			return this.data && this.data.items ? this.data.items.length : 0;
		}
	},
	props: {
		ads: Object
	},
	mounted() {
		this.refresh();
		this.ads.bus.$on("filter_changed", (name) => {
			if (name !== this.myself) {
				this.breadCrumb = this.ads.getInteractiveBreadcrumb();
				this.$refs.breadCrumb.addEventListener('click', (event) => {
					if (event.target.classList.contains("close")) {
						this.ads.setFilter(event.target.getAttribute("filter"), null);
					}
				});
			}
			this.refresh();
		});
	},
	methods: {
		search() {
			if (this.searchTimeout) {
				clearTimeout(this.searchTimeout);
			}

			this.searchTimeout = setTimeout(async () => {
				this.refresh();
			}, 700);
		},
		sort(context) {
			this.context.sortBy = context.sortBy;
			this.context.sortDesc = context.sortDesc;
			this.refresh();
		},
		refresh() {
			this.getData().then((data) => {
				if(data && data.data) {
					this.create(data.data);
				}
			});
		},
		async getData() {
			this.inProgress = true;

			const filters = this.ads.getFiltersFor(this.myself);
			const queryParams = filters ? filters : {};
			queryParams.ctx = this.ads.ctx;

			let result = this.ads.companyId ? companyService.getAnalyticsSummary(this.ads.companyId, this.myself, queryParams, this.context) : {};
			
			this.inProgress = false;
			return result;
		},
		create(data) {

			if(this.targetStatusFilter) {
				data = data.filter((t) => { return t.deleted != 1 });
			}

			data.forEach((row) => {
				row.click_rate = row.click_rate.toFixed(1) + "%";
				row.clicked_last = row.clicked_last == 1? this.$t('Analytics.Table.Yes') : this.$t('Analytics.Table.No');
				row.subject = row.type==1 ? row.subject : row.template_name;

				// Task 226 - anonymise Target personal data according the given permissions
				if(!this.$checkPermission('view-target-details')) {
					row.name = this.$t('Analytics.Table.Target') + "_" + row.name;
				}

				if(typeof row.risk_group != 'number') {
					row.risk_group = this.$t('Analytics.Table.UnratedClickers');
				} else {
					switch(row.risk_group) {
						case 1:
							row.risk_group = this.$t('Analytics.Table.SerialClickers');
							break;
						case 2:
							row.risk_group = this.$t('Analytics.Table.FrequentClickers');
							break;
						case 3:
							row.risk_group = this.$t('Analytics.Table.RareClickers');
							break;
						case 4:
							if(this.reportingEnabled) {
								row.risk_group = this.$t('Analytics.Table.DefenderUsers');
							} else {
								row.risk_group = this.$t('Analytics.Table.RareClickers');
							}
							break;
						default:
							row.risk_group = this.$t('Analytics.Table.UnratedClickers');
							break;
					}
				}
			});

			let fields = [
				{ key: 'name', label: this.$t('Analytics.Table.Target'), sortable: true },
				{ key: 'org_1', label: this.$t('Analytics.Table.Org'), sortable: true, class: 'text-center' },
				{ key: 'sent', label: this.$t('Analytics.Table.Sent'), sortable: true, class: 'text-center' },
				{ key: 'clicked', label: this.$t('Analytics.Table.Clicked'), sortable: true, class: 'text-center' },
				{ key: 'click_rate', label: this.$t('Analytics.Table.ClickRate'), sortable: true, class: 'text-center' },
				{ key: 'clicked_last', label: this.$t('Analytics.Table.HasClickedLast'), sortable: true, class: 'text-center' },
				{ key: 'risk_group', label: this.$t('Analytics.Table.RiskGroup'), sortable: true, class: 'text-center' },
				{ key: 'subject', label: this.$t('Analytics.Table.LastTemplateSubject'), sortable: true, class: 'd-none d-md-table-cell text-center' }
			]; 
    
			let currentItemsPerPage = this.data.perPage? this.data.perPage : 15;

			this.data = {
				ref_id: "click_by_clickers",
				paginated: true,
				items: data,
				fields: fields,
				sortBy: this.context.sortBy,
				sortDesc: this.context.sortDesc,
				totalRows: data.length,
				perPage: currentItemsPerPage,
				currentPage: 1
			};
		}
	},
	async created() {
		let company = await companyService.getCompany(localStorage.getItem("cg-company"));
		this.reportingEnabled = company? !!company.data.reporting_enabled : false;

		this.targetStatusOption = [
			{ value: null, text: this.$t('Targets.SuspendedAndActiveOption')},
			{ value: 1, text: this.$t('Targets.ActiveOption')}
		];
	}
}
</script>

<style lang="less">
#clickRateByClickers {
	.b-table-sticky-header {
		max-height: 600px;
	}
	.row-counter {
		font-size: 14px; 
		font-style: italic;
	}
	
}
</style>
