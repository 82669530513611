<template>
	<b-card id="clickRateByOrg" class="min-height-unset">
		<b-card-title class="mb-4">
			<div class="title" :title="widgetTitle">
				{{ widgetTitle }}
				<font-awesome-icon id="clickRateByOrgTooltip" :icon="['fas', 'info-circle']" class="mr-1"/>
				<b-tooltip class="mt-2 mr-2" triggers="hover" right target="clickRateByOrgTooltip">
					<span v-html="$t('Analytics.Tooltips.ClickRateByOrgInfo')"></span>
				</b-tooltip>
			</div>
			<b-col class="filter-container">
				<span v-html="breadCrumb" @click="removeFilter"></span>
			</b-col>
			<b-dropdown v-if="!inProgress" right class="pie-dropdown">
				<template v-slot:button-content>
					<label class="sr-only">{{ $t('Analytics.ClickRateBy') }}</label>
					<font-awesome-icon :icon="['fas', 'ellipsis-v']" />
				</template>
				<b-dropdown-item v-if="companyData['use_org_1']" @click="selectChart" myself="org_1">{{ fetchOrgName("org_1") }}</b-dropdown-item>
				<b-dropdown-item v-if="companyData['use_org_2']" @click="selectChart" myself="org_2">{{ fetchOrgName("org_2") }}</b-dropdown-item>
				<b-dropdown-item v-if="companyData['use_org_3']" @click="selectChart" myself="org_3">{{ fetchOrgName("org_3") }}</b-dropdown-item>
				<b-dropdown-item v-if="companyData['use_org_4']" @click="selectChart" myself="org_4">{{ fetchOrgName("org_4") }}</b-dropdown-item>
			</b-dropdown>
			<b-spinner class="spinner" variant="primary" :label="$t('General.Loading')" v-if="inProgress"></b-spinner>
		</b-card-title>

		<!-- Heatmap -->
		<b-row ref="heatmap" v-for="(org, index) in map" :key="index" class="heatmap-row mx-4"
			:class="[rowClicked == -1 ? '' : rowClicked == index ? 'heatmap-active-row' : 'heatmap-unactive-row']">

			<!-- Heatmap shows campaign click rate by org -->
			<b-col class="pl-0 heatmap-org" :id="org.org_label" @click="filter(org)" :style=" {'min-width' : minWidth + 'px'}">{{org.org_label}}</b-col>

			<b-tooltip :target="org.org_label" :title="org.org_label">
				<span><b>{{ org.org_label }}</b></span>
			</b-tooltip>

			<!-- For each org, display a cell with each campaign click rate -->
			<b-col class="px-0 heatmap-col" v-for="(campaign, count) in org.campaigns" :key="count">
				<b-col class="heatmap-clickrate px-0" :style="'background-color: ' + gradient.rgbAt((campaign.data.click_rate/100)) + ';'" 
					tabindex="0" :id="org.org_label + '_' + campaign.name" @click="filter(org, campaign)">{{ Number(campaign.data.click_rate).toFixed(1) }}%
				</b-col>
				<!-- Display campaign name after the last row -->
				<b-col v-if="index == map.length -1" class="heatmap-campaign" @click="filter(null, campaign)">
					<b-tooltip :target="campaign.name" :title="campaign.name">
						<span><b>{{ campaign.data.period }}</b></span><br>
					</b-tooltip>
					<span :id="campaign.name" class="heatmap-campaign-name">{{campaigns[count]}}</span>
				</b-col>

				<!-- Heatmap cell tooltip -->
				<b-tooltip :target="org.org_label+'_'+campaign.name" :title="campaign.name">
					<span><b>{{ org.org_label }}</b></span><br>
					<span>{{ campaign.name }}</span><br>
					<span>{{ $t('Analytics.Heatmap.ClickRate', { param: campaign.data.click_rate + '%' }) }}</span><br>
					<span>{{ $t('Analytics.Heatmap.Clicked', { param: campaign.data.clicked }) }}</span><br>
					<span>{{ $t('Analytics.Heatmap.Sent', { param: campaign.data.sent }) }}</span>
				</b-tooltip>

			</b-col>
		</b-row>

		<b-card-text v-if="!map.length" class="text-center mb-3">{{ $t('Analytics.Table.Empty') }}</b-card-text>
	</b-card>
</template>

<script>
import companyService from "@/services/company.service";
import colors from "@/components/charts/utils/colors";
import tinygradient from "tinygradient";

export default {
	data() {
		return {
			orgs: [],
			campaigns: [],
			map: [],
			myself: "org_1",
			companyData: {
				'org_1': null,
				'org_2': null,
				'org_3': null,
				'org_4': null
			},
			breadCrumb: "",
			inProgress: false,
			gradient: [],
			max: 0,
			rowClicked: -1,
			widgetTitle: ""
		};
	},
	props: {
		ads: Object
	},
	computed: {
		minWidth() {
			if(!this.map) return 150;
			// checks if the longest org_label exceeds 300px width
			const max = Math.max(...this.map.map(o => o.org_label.length));
			let tempWidth = (10 * max);
			return tempWidth > 450 ? 450 : (tempWidth < 150 ? 150 : tempWidth);
		}
	},
	mounted() {
		this.refresh();

		this.ads.bus.$on("filter_changed", (id, value, skip) => {
			if(skip === true) return;
			this.breadCrumb = this.ads.getInteractiveBreadcrumb();

			this.refresh();
		});

		this.$on("chartChanged", async (myself) => {
			this.myself = myself;
			this.widgetTitle = this.$t('Analytics.ClickRateByParam', { param: this.fetchOrgName(this.myself) });

			this.refresh();
		});
	},
	async created() {		
		let result = this.ads.companyId ? await companyService.getCompany(this.ads.companyId) : {};
		this.companyData = result.data;

		this.widgetTitle = this.$t('Analytics.ClickRateByParam', { param: this.fetchOrgName(this.myself) });
	},
	methods: {
		async refresh() {
			await this.getData().then((data) => {
				if(data && data.data) {
					this.createHeatMap(data.data, true);
				}
			});

			this.toggleActive();
		},
		async getData() {
			this.inProgress = true;

			const filters = this.ads.getFiltersFor();
			const queryParams = filters ? filters : {};
			queryParams.ctx = this.ads.ctx;

			let result = this.ads.companyId ? companyService.getAnalyticsSummary(this.ads.companyId, this.myself, queryParams) : {};

			this.inProgress = false;
			return result;			
		},
		createHeatMap(data, reload) {

			if(data.length == 0) {
				return;
			}

			// Add click rate numeric value to the dataset (always performed) and evaluate max click rate
			this.max = 0;
			data.forEach((row) => {
				row.click_rate = row.sent > 0 ? Number(100 * (row.clicked / row.sent)).toFixed(1) : 0;
				if(row.click_rate - this.max > 0) {
					this.max = row.click_rate;
				}
			});

			// Generate click rate gradient according to max click rate value found
			if(this.max == 0) {
				// If the max is 0, we define a custom green gradient
				this.gradient = tinygradient([ {color: '#b6e5bc'}, {color: '#b6e5bc'} ]);
			} else {
				this.gradient = tinygradient([
					{color: '#b6e5bc', pos: 0 },
					{color: colors.alpha.yellow, pos: this.max/200 },
					{color: '#f7797d', pos: this.max/100 }
				]);
			}

			// When the view is first rendered or reloaded, get org and campaign list
			data.sort((a,b) => { return a.sequence - b.sequence });
			this.orgs = [...new Set(data.map(item => item[this.myself]))];
			this.campaigns = [...new Set(data.map(item => item.campaign))];
			this.campaign_meta = [];
			this.campaigns.forEach((c) => {
				let camp = data.filter(a => c === a.campaign)[0];
				this.campaign_meta[c] = { 
					'id': camp.campaign_id, 
					'period': `${this.$moment.utc(camp.planned_start).local().locale(this.$i18n.locale).format("YYYY-MM-DD")} 
						- ${this.$moment.utc(camp.planned_end).local().locale(this.$i18n.locale).format("YYYY-MM-DD")}`
				}
			});

			// Get a copy of current heatmap matrix 
			var tempMap = this.map;

			// Empty heatmap matrix
			this.map = [];

			// For each org, represented on rows
			this.orgs.forEach((org) => {
				var tempCampaigns = [];

				// For each campaign, represented on columns
				this.campaigns.forEach((campaign) => {

					// Find the correspondant element, if any. This is a single heatmap "cell"
					let cell = data.filter((el) => { return el[this.myself] == org && el.campaign == campaign; });
					if(cell.length > 0) {
						cell[0].period = this.campaign_meta[campaign].period;
					}

					if(reload) {
						// Heatmap is forced to reload
						if(cell.length > 0) {
							tempCampaigns.push({'name': campaign, 'data': cell[0] });
						} else {
							tempCampaigns.push({'name': campaign, 'data': { 'campaign_id': this.campaign_meta[campaign].id, 'click_rate': 0, 'sent': 0, 'clicked': 0, 'period': this.campaign_meta[campaign].period } });
						}
					} else {
						// The heatmap is already initialized, there are already (org * campaign) cell defined
						
						// Find in the heatmap copy the right row to update
						let o = tempMap.filter((el) => { return el[this.myself] == org });
						// Find in the row the right cell to update
						let c = o[0].campaigns.filter((el) => { return el.name == campaign; });
						
						if(cell.length > 0) { 
							// If data for that cell has been found, update the cell
							c[0].data = cell[0];
						} else {
							// Blank the cell since no data has been found
							c[0].data = { 'campaign_id': this.campaign_meta[campaign].id, 'click_rate': 0, 'sent': 0, 'clicked': 0, 'period': this.campaign_meta[campaign].period };
						}
						
						// Save the cell
						tempCampaigns.push(c[0]);
					}
				});

				let orgLabel = org == null ? this.$i18n.t('Analytics.NA') : org;
				let orgName = org == null ? this.myself + "_" + this.ads.NotAssignedLabel : org;

				// Fill the map row by row
				this.map.push({ [this.myself]: org, 'org_filter_name': orgName, 'org_label': orgLabel, 'campaigns': tempCampaigns });
			});
		},
		toggleActive() {
			let filters = Object.keys(this.ads.filter);
			if(filters.indexOf(this.myself) != -1) {
				this.rowClicked = this.map.map((el) => { return el.org_filter_name; }).indexOf(this.ads.filter[this.myself]);
			} else {
				this.rowClicked = -1;
			}
		},
		fetchOrgName(org) {
			return this.companyData && this.companyData[org] ? this.companyData[org] : org;
		},
		async filter(org, camp) {
			const filter = org ? (org[this.myself] ? org[this.myself] : this.myself + "_" + this.ads.NotAssignedLabel) : null;
			const campaign = camp && camp.data? { campaign_id: camp.data.campaign_id, name: camp.name } : null;

			if(filter) {
				if(!campaign) {
					// Single filter applied, as usual
					this.ads.setFilter(this.myself, filter);
				} else {
					// Skip refresh, another filter is soon to be applied
					this.ads.setFilter(this.myself, filter, null, true);
				}
			}
			
			if(campaign) {
				// First chart to implement 2 different filters
				this.ads.setFilter('campaign', campaign.campaign_id, campaign.name);
			}
		},
		removeFilter(event) {
			if (event.target.classList.contains("close")) {
				this.ads.setFilter(event.target.getAttribute("filter"), null);
			}
		},
		selectChart(event) {
			// this.ads.setFilter(this.myself, null);
			this.breadCrumb = this.ads.getInteractiveBreadcrumb();
			this.$emit("chartChanged", event.target.getAttribute("myself"));
		}
	}
}
</script>

<style lang="less">
#clickRateByOrg {
	.heatmap-row {
		text-align: center; 
		cursor: pointer;
	}
	.heatmap-org {
		line-height: 35px; 
		height: 35px; 
		white-space: nowrap; 
		overflow: hidden; 
		text-overflow: ellipsis;
	}
	.heatmap-col {
		margin-bottom: 1px; 
		margin-right: 1px;
	}
	.heatmap-campaign {
		display: inline-grid;
	}
	.heatmap-campaign-name {
		white-space: nowrap; 
		font-size: 12px; 
		overflow: hidden; 
		text-overflow: ellipsis;
	}
	.heatmap-clickrate {
		line-height: 35px;
	}
	.heatmap-unactive-row {
		-webkit-filter: grayscale(50%);
		-moz-filter: grayscale(50%);
		-ms-filter: grayscale(50%);
		-o-filter: grayscale(50%);
		filter: grayscale(50%);
		filter: gray;
		.heatmap-org {
			font-weight: normal;
		}
	}
	.heatmap-active-row {
		.heatmap-org {
			font-weight: bold;
		}
	}
	.card-title {
		height: fit-content !important;
	}
}
.tooltip.b-tooltip {
	opacity: 1;
}
.tooltip-inner {
	min-width: 100px;
	background-color: white;
	color: #2c3e50;
	font-size: 12px;
	border: 1px solid #2c3e50;
	font-family: "Avenir", Helvetica, Arial, sans-serif;
}
.bs-tooltip-top .arrow::before {
	border-top-color: #2c3e50;
	border-width: 0.2rem 0.2rem 0;
}
.min-height-unset {
	min-height: unset !important;
}

// Small devices (landscape phones, 576px and up)
@media only screen and (min-width: 575px) { }

// Medium devices (tablets, 768px and up)
@media only screen and (min-width: 767px) {
	.heatmap-clickrate {
		font-size: 11px;
	}
}

// Large devices (desktops, 992px and up)
@media only screen and (min-width: 991px) {
	.heatmap-clickrate {
		font-size: 12px;
	}
}

// Extra large devices (large desktops, 1200px and up)
@media only screen and (min-width: 1199px) { 
	.heatmap-clickrate {
		font-size: 14px;
	}
}

// Not so large devices (1199px and below)
@media only screen and (max-width: 1199px) {
	#clickRateByOrg {
		.heatmap-org {
			min-width: calc(100% / 5) !important;
		}
	}
}
</style>