<template>
	<b-card id="analyticsPie" class="h-100">
		<b-card-title class="mb-4">
			<div class="title" :title="title">
				{{title}}
			</div>
			<b-col class="filter-container">
				<span v-html="breadCrumb" @click="removeFilter"></span>
			</b-col>
			<b-dropdown v-if="!inProgress" right class="pie-dropdown">
				<template v-slot:button-content>
					<label class="sr-only">{{ $t('Analytics.ClickRateBy') }}</label>
					<font-awesome-icon :icon="['fas', 'ellipsis-v']" />
				</template>
				<b-dropdown-item @click="selectChart" myself="device">{{ $t('Analytics.Device') }}</b-dropdown-item>
				<b-dropdown-item @click="selectChart" myself="os">{{ $t('Analytics.OS') }}</b-dropdown-item>
				<b-dropdown-item @click="selectChart" myself="browser">{{ $t('Analytics.Browser') }}</b-dropdown-item>
				<b-dropdown-item v-if="smsCampaignEnabled" @click="selectChart" myself="type">{{ $t('Analytics.Type') }}</b-dropdown-item>
			</b-dropdown>
			<b-spinner class="spinner" variant="primary" :label="$t('General.Loading')" v-if="inProgress"></b-spinner>
		</b-card-title>
		<div v-show="isEmpty" class="empty-message pie">{{ $t('Analytics.Table.Empty') }}</div>
		<b-row class="mt-5 pl-3 d-flex chart-container" v-show="!isEmpty">
			<b-col :cols="12 - legendSize">
				<cg-doughnut-chart
					v-if="showChart"
					:chart-data="chartData"
					:options="options"
					:height="320"
					ref="chart"
				></cg-doughnut-chart>
			</b-col>
			<b-col class="legend-container" v-show="!isEmpty">
				<span class="html-legend" v-html="htmlLegend" ref="legend"></span>
			</b-col>
		</b-row>
	</b-card>
</template>

<script> 
import DoughnutChart from "@/components/charts/wrappers/DoughnutChart";
import CompanyService from "@/services/company.service";
import colors from "@/components/charts/utils/colors";
import { randomColorGenerator } from "@/components/charts/utils/colors_generator";
import { PieChartOptions } from "@/components/charts/utils/options";

export default {
	components: {
		"cg-doughnut-chart": DoughnutChart
	},
	data() {
		return {
			myself: "device",
			selectedIndex: {},
			breadCrumb: "",
			chartData: {},
			data: {},
			options: {},
			inProgress: false,
			showChart: false,
			title: this.$t('Analytics.ClickByDevice'),
			attribute: "phished_device",
			devices: [
				this.$t('Analytics.Devices.Other'),
				this.$t('Analytics.Devices.Desktop'),
				this.$t('Analytics.Devices.Tablet'),
				this.$t('Analytics.Devices.Mobile')
			],
			template_types: [
				this.$t('Analytics.NA'),
				this.$t('Analytics.TemplateTypes.Email'),
				this.$t('Analytics.TemplateTypes.Sms')
			],
			isEmpty: false,
			myselfClicked: false,
			htmlLegend: null,
			darkPalette: [],
			lightPalette: [],
			legendSize: 6,
			smsCampaignEnabled: false
		};
	},
	props: {
		ads: Object
	},
	async mounted() {
		await this.getCompanyData();
		await this.init();

		this.ads.bus.$on("filter_changed", () => {
			this.breadCrumb = this.ads.getInteractiveBreadcrumb();
			this.refresh();
		});

		this.$on("chartChanged", (myself) => {
			this.myself = myself;
			this.attribute = "phished_" + myself;
			this.title = this.$t('Analytics.ClickBy' + myself.charAt(0).toUpperCase() + myself.slice(1));

			this.init();
			this.refresh();
		});
	},
	updated() {
		if (this.$refs.legend.childNodes.length > 0) {
			this.$refs.legend.childNodes[0].childNodes.forEach(li => {
				li.addEventListener("click", this.legendClickCallback, false);
			});
		}
	},
	methods: {
		async init() {
			this.setOptions();

			let data = await this.getData();
			this.isEmpty = false;

			this.createChart(data);
			
			// Manage empty dataset layout
			if (!data || Object.keys(data[0]).length == 0) {
				this.isEmpty = true;
			}
		},
		async refresh() {
			const hasFilters = this.ads.hasOtherValidFilters(this.myself);

			let data = !this.myselfClicked ? await this.getData() : null;

			if (hasFilters) {
				if (data) this.addDataset(data);
			} else {
				this.removeDataset();
			}
			this.toggleActive();
			this.$refs.chart.$data._chart.update();
		},
		// Retrieve the clicker rate
		async getData() {
			this.inProgress = true;

			let queryParams = {};
			const filters = this.ads.getFiltersFor(this.myself);

			queryParams = filters ? filters : {};
			queryParams.ctx = this.ads.ctx;

			let result = this.ads.companyId ? await CompanyService.getAnalyticsSummary(this.ads.companyId, this.myself, queryParams) : {};

			// Sort results by clicked value
			if(result.data) {
				result.data = result.data.sort((a, b) => b.clicked - a.clicked);
			}

			this.inProgress = false;
			this.showChart = true;

			// Empty dataset should not break the chart
			if(result.data && result.data.length == 0) {
				return [{}];
			}
			return result.data;
		},
		createChart(data) {
			this.data[this.myself] = {
				labels: [],
				datasets: [],
				devices: [],
				template_types: []
			};
			this.createPalette(data);
			this.addDataset(data);
			/** this.addClickHandler(); */ // Commented click handler to prevent filtering issues
			this.createLegend();
			this.$refs.chart.renderChart(this.chartData, this.options);
		},
		createPalette(data) {
			this.darkPalette = colors.palette.dark.slice();
			this.lightPalette = colors.palette.light.slice();

			const colorGenDark = randomColorGenerator(0.38);
			const colorGenLight = randomColorGenerator(0.08);

			data.forEach((d, index) => {
				if (index >= this.darkPalette.length) {
					this.darkPalette.push(colorGenDark.next().value);
				}
				if (index >= this.lightPalette.length) {
					this.lightPalette.push(colorGenLight.next().value);
				}
			});
		},
		addDataset(data) {
			const first = this.data[this.myself].datasets.length == 0;

			if (first) {
				let dataset = {
					data: [],
					active: [],
					backgroundColor: [],
					hoverBackgroundColor: [],
					borderWidth: 1,
					hoverBorderWidth: 0
				};
				data.forEach((summary, index) => {
					let label = '';
					if (this.myself === "device") {
						this.data[this.myself].devices.push(summary[this.attribute]);
						label = this.devices[summary[this.attribute] - 1];
					} else if (this.myself === "type") {
						this.data[this.myself].template_types.push(summary[this.attribute]);
						label = this.template_types[summary[this.attribute]];
					} else {
						label = summary[this.attribute];
					}
					dataset.backgroundColor.push(this.darkPalette[index]);
					// dataset.hoverBackgroundColor.push(this.darkPalette[index]);
					dataset.data.push(summary.clicked);
					this.data[this.myself].labels.push(label);
					dataset.active.push(false);
				});

				this.data[this.myself].datasets[0] = dataset;
			} else {
				let dataset = {
					data: this.data[this.myself].datasets[0].data.map(() => null),
					backgroundColor: this.chartData.datasets[0].backgroundColor.map((b, index) => this.darkPalette[index]),
					hoverBackgroundColor: this.chartData.datasets[0].hoverBackgroundColor.map((b, index) => this.darkPalette[index]),
					borderWidth: 1
				};
				data.forEach(summary => {
					let idx = '';
					if(this.myself === "device") {
						idx = this.data[this.myself].devices.indexOf(summary[this.attribute]);
					} else if (this.myself === "type") {
						idx = this.data[this.myself].template_types.indexOf(summary[this.attribute]);
					} else {
						idx = this.data[this.myself].labels.indexOf(summary[this.attribute]);
					}
					dataset.data[idx] = summary.clicked;
				});

				this.data[this.myself].datasets[1] = dataset;
			}
			this.chartData = this.data[this.myself];
		},
		removeDataset() {
			if (this.data[this.myself] && this.data[this.myself].datasets[1]) {
				this.data[this.myself].datasets.pop();
			}
		},
		// Register the click event on the chart
		addClickHandler() {
			var canvas = this.$refs.chart.$data._chart.canvas;
			canvas.onclick = this.handleClick;
			canvas.onmousemove = this.handleHover;
		},
		createLegend() {
			this.$refs.chart.addPlugin({
				id: 'custom-legend',
				beforeDraw: (chart) => {
					this.htmlLegend = chart.generateLegend();
				}
			});
		},
		// Set the options for the chart
		setOptions() {
			this.options = JSON.parse(JSON.stringify(PieChartOptions));
			this.options.legend.display = false;
			this.options.plugins = Object.assign({}, PieChartOptions.plugins);

			this.options.tooltips.callbacks.label = (tooltipItem, data) => {
				let category = data.labels[tooltipItem.index];
				let dataset = data.datasets[tooltipItem.datasetIndex];
				let index = tooltipItem.index;

				const value = dataset.data[index];
				const calculatedData = dataset.data.slice();

				// When calculating the tooltip percent value, remove from the total the hidden categories
				const chart = this.$refs.chart.$data._chart;
				let meta = chart.getDatasetMeta(0);

				meta.data.forEach((el, index) => {
					if(el.hidden) {
						calculatedData[index] = 0;
					}
				});

				const total = calculatedData.reduce((a, b) => { return a + b; });
				const percentage = (100 * (calculatedData[index] / total)).toFixed(1);
				const label =  percentage > 0 && !isNaN(percentage) ? " - " + percentage + "%" : "";

				return category ? category + ": " + value + label : "N.A." + ": " + value + label;
			}

			// Create the html legend
			this.options.legendCallback = (chart) => {
				let legendHtml = [];
				legendHtml.push('<ul>');

				let item = chart.data.datasets[0];
				let maxLabelLenght = 0;

				item.data.forEach((d, index) => {
					const label = (chart.data.labels[index] || this.$t("Analytics.NA"));
					const bgColor = item.backgroundColor[index];
					let meta = chart.getDatasetMeta(0);

					maxLabelLenght = maxLabelLenght < label.length ? label.length : maxLabelLenght;

					let itemClass = meta.data[index].hidden ? 'hidden' : '';

					legendHtml.push('<li title="' + label + '" class="' + itemClass + '">');
					legendHtml.push('<span id="chart-legend-box" style="background-color:' + bgColor + '"></span>');
					legendHtml.push('<span id="chart-legend-label">' + label + ' </span>');
					legendHtml.push('</li>');

				});

				this.legendSize = maxLabelLenght <= 20 ? (maxLabelLenght <= 12 ? (maxLabelLenght <= 6 ? 3 : 4) : 5) : 6;

				legendHtml.push('</ul>');
				return legendHtml.join("");
			};
		},
		// Highligth the selected slice of the pie chart
		highlightDataset(elementIndex, active) {
			this.chartData.datasets[0].backgroundColor[elementIndex] = active ? this.darkPalette[elementIndex] : this.lightPalette[elementIndex];
		},
		// Handle the click event
		handleClick(evt) {
			const chart = this.$refs.chart.$data._chart;
			let activeElement = chart.getElementAtEvent(evt);

			if (activeElement.length > 0) {
				activeElement = activeElement[0];
				if (0 == activeElement._datasetIndex) {
					this.myselfClicked = true;
					const reset = this.selectedIndex[this.myself] == activeElement._index;
					this.selectedIndex[this.myself] = reset ? null : activeElement._index;

					if (this.myself === "device") {
						this.ads.setFilter(this.myself, reset ? null : this.data[this.myself].devices[activeElement._index], this.data[this.myself].labels[activeElement._index]);
					} else if (this.myself === "type") {
						this.ads.setFilter(this.myself, reset ? null : this.data[this.myself].template_types[activeElement._index], this.data[this.myself].labels[activeElement._index]);
					} else {
						this.ads.setFilter(this.myself, reset ? null : this.data[this.myself].labels[activeElement._index]);
					}
				}
			}
		},
		// Handle the click event on the legend
		legendClickCallback(event) {
			let chart = this.$refs.chart.$data._chart;
			var target = event.target;

			while (target.nodeName !== 'LI') {
				target = target.parentElement;
			}

			let index = Array.prototype.slice.call(target.parentElement.children).indexOf(target);

			chart.data.datasets.forEach((dataset, datasetindex) => {
				let meta = chart.getDatasetMeta(datasetindex);

				let item = meta.data[index];
				item.hidden = !item.hidden;
			});

			target.classList.toggle('hidden');
			chart.update();
		},
		handleHover(evt) {
			const chart = this.$refs.chart.$data._chart;
			let activeElement = chart.getElementAtEvent(evt);

			if (activeElement.length > 0) {
				activeElement = activeElement[0];
				evt.target.style.cursor = activeElement._datasetIndex == 0 ? 'pointer' : 'default';

				const active = this.chartData.datasets[0].active[activeElement._index];

				if (activeElement._datasetIndex == 0) {
					activeElement._chart.update();
					activeElement._model.outerRadius = !active ? activeElement._model.outerRadius + 10 : activeElement._model.outerRadius;
					activeElement._model.innerRadius = this.chartData.datasets.length > 1 && !active ? activeElement._model.innerRadius + 10 : activeElement._model.innerRadius;
				}
			} else {
				evt.target.style.cursor = 'default';
				this.$refs.chart.$data._chart.update();
			}
		},
		// Toggle active status
		toggleActive() {
			const elementIndex = this.selectedIndex[this.myself];
			const myselfActive = this.ads.filter[this.myself];
			const othersActive = this.ads.hasOtherValidFilters(this.myself);
			this.myselfClicked = false;

			this.data[this.myself].datasets[0].data.forEach((value, index) => {
				this.data[this.myself].datasets[0].active[index] = (index == elementIndex) && myselfActive;
				this.highlightDataset(index, myselfActive ? this.data[this.myself].datasets[0].active[index] : !othersActive);
			});
			this.chartData = this.data[this.myself];
		},
		removeFilter(event) {
			if (event.target.classList.contains("close")) {
				this.ads.setFilter(event.target.getAttribute("filter"), null);
			}
		},
		selectChart(event) {
			this.$emit("chartChanged", event.target.getAttribute("myself"));
		},
		async getCompanyData() {
			let company = await CompanyService.getCompany(localStorage.getItem("cg-company"));
			this.smsCampaignEnabled = company? !!company.data.sms_enabled : false;
		}
	}
}
</script>

<style lang="less">
#analyticsPie {
	.empty-message.pie {
		position: relative;
		top: 100px;
	}
}
</style>