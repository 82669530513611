<template>
	<b-card id="clickRateByOrgs">
		<b-card-title class="mb-4">
			<div class="title" :title="title + ' ' + currentOrgLevel">
				{{ title + " " + currentOrgLevel }}
				<font-awesome-icon id="clickRateByOrgsTooltip" :icon="['fas', 'info-circle']" class="mr-1"/>
				<b-tooltip class="mt-2 mr-2" triggers="hover" right target="clickRateByOrgsTooltip">
					<span v-html="$t('Analytics.Tooltips.ClickRateByOrgsInfo')"></span>
				</b-tooltip>
			</div>
			<b-col class="filter-container">
				<span v-html="breadCrumb" @click="removeFilter"></span>
			</b-col>
			<b-col md="5" class="card-tabs">
				<b-list-group horizontal ref="tabList">
					<b-list-group-item button @click="selectOrgLevel" v-for="(org, index) in orgLevels" :key="index" :orgLevel="org.attr"
						:idx="index" :class="[index == currentOrgIndex ? 'active' : '']" :title="org.label" >{{ org.label }}
					</b-list-group-item>
				</b-list-group>
			</b-col>

			<b-dropdown v-if="!inProgress" right class="tab-dropdown">
				<template v-slot:button-content>
					<label class="sr-only">{{ $t('Analytics.ClickRateBy') }}</label>
					<font-awesome-icon :icon="['fas', 'ellipsis-v']" />
				</template>
				<b-dropdown-item @click="selectOrgLevel" v-for="(org, index) in orgLevels" :key="index" :orgLevel="org.attr" :idx="index"
					:class="[index == currentOrgIndex ? 'active' : '']" :title="org.label" >{{ org.label }}
				</b-dropdown-item>
			</b-dropdown>
			<b-spinner class="spinner" variant="primary" :label="$t('General.Loading')" v-if="inProgress"></b-spinner>
			<font-awesome-icon v-if="!inProgress && ads.expanded" class="expand-icon" :icon="['fas', 'expand']" @click="ads.expanded = !ads.expanded" />
			<font-awesome-icon v-if="!inProgress && !ads.expanded" class="compress-icon" :icon="['fas', 'compress']" @click="ads.expanded = !ads.expanded" />
		</b-card-title>
		<div class="overflow-auto">
			<cg-bar-chart v-if="showChart" :chart-data="chartData" :options="options" ref="chart" :style="{'min-width' : minWidth + `px` }"></cg-bar-chart>
		</div>
	</b-card>
</template>

<script> 
import BarChart from "@/components/charts/wrappers/BarChart";
import CompanyService from "@/services/company.service";
import { BarChartsOptions } from "@/components/charts/utils/options";
import colors from "@/components/charts/utils/colors";
import { truncateStr } from "@/components/utils";

export default {
	components: {
		"cg-bar-chart": BarChart
	},
	data() {
		return {
			myself: null,
			title: this.$t("Analytics.ClickRateBy"),
			chartData: {},
			selectedIndex: {},
			breadCrumb: "",
			data: {},
			options: {},
			inProgress: false,
			showChart: false,
			orgLevels: [],
			currentOrgLevel: null,
			currentOrgIndex: 0,
			myselfClicked: false
		};
	},
	props: {
		ads: Object
	},
	computed: {
		minWidth() {
			if(!this.chartData.datasets || !this.chartData.datasets[0]) return 'inherit';
			// Namely add 300px for 15 elements, plus 300px for each 60 elements
			return '' + ((Math.ceil(this.chartData.datasets[0].data.length / 10) * 300) + (300 * Math.ceil(this.chartData.datasets[0].data.length / 60)));
		}
	},
	async mounted() {
		this.setOptions();
		await this.fetchOrgLevels();
		await this.init();

		// Listen to the filter changed event
		this.ads.bus.$on("filter_changed", () => {
			this.breadCrumb = this.ads.getInteractiveBreadcrumb();
			this.refresh();
		});

		// Listen to the org changed event emitted on click the tab 
		this.$on("orgChanged", async(orgLevel, orgIdx) => {
			this.myself = orgLevel;
			this.currentOrgLevel = this.orgLevels[orgIdx].label;
			await this.init();
			await this.refresh();
		});
	},
	methods: {
		async init() {
			let data = await this.getData();
			if (data) this.createChart(data);
		},
		// Get the filtered data and refresh the ui
		async refresh() {
			const hasFilters = this.ads.hasOtherValidFilters(this.myself);
			
			let data = !this.myselfClicked ? await this.getData() : null;

			if (hasFilters) {
				if (data) this.addDataset(data);
			} else {
				this.removeDataset();
			}
			this.toggleActive();
		},
		// Retrieve the org levels labels 
		async fetchOrgLevels() {
			let result = this.ads.companyId ? await CompanyService.getCompany(this.ads.companyId) : {};
			let data = result.data || {};
			
			// Search only enabled org labels
			for (const field in data) {
				if (field.indexOf("use_org_") === -1) {
					if (data["use_" + field]) {
						if (!this.myself) {
							this.myself = field;
						}
						let orgLevel = data[field] ? data[field] : field;
						this.orgLevels.push({ "attr": field, "label": orgLevel });
					}
				}
			}

			if(this.$account.filtered_orgs[this.ads.companyId]) {
				// Filter orgs not used in filters
				let firstOrg = ['org_1', 'org_2', 'org_3', 'org_4'].filter((o) => { return !this.$account.filtered_orgs[this.ads.companyId][o] });
				// Assign first org "available", or fall into 'org_1' default
				this.myself = firstOrg && firstOrg.length > 0 ? firstOrg[0] : (this.orgLevels.length > 0 ? this.orgLevels[0].attr : "org_1");
				// Retrieve shown org index
				this.currentOrgIndex = this.orgLevels.findIndex(o => o.attr == this.myself) || 0;
				// Retrieve shown org label
				let shownOrg = this.orgLevels.filter(o => o.attr == this.myself);
				this.currentOrgLevel = shownOrg && shownOrg.length > 0 ? shownOrg[0].label : "org_1";
			} else {
				this.currentOrgLevel = this.orgLevels.length > 0 ? this.orgLevels[0].label : "org_1";	
			}
		},
		// Retrieve the clicker rate by organizational level
		async getData() {
			this.inProgress = true;

			const filters = this.ads.getFiltersFor(this.myself);

			// Build the query params to pass to the api
			const queryParams = filters ? filters : {};
			queryParams.orgLevel = this.myself;
			queryParams.ctx = this.ads.ctx;

			// Get the click rate for the current org level
			let result = this.ads.companyId ? await CompanyService.getOrgSummary(this.ads.companyId, queryParams) : {};
			this.inProgress = false;
			this.showChart = true;
			return result.data;
		},
		// Create the chart
		createChart(data) {
			this.data[this.myself] = {
				labels: [],
				datasets: []
			};
			this.addDataset(data);
			this.setOptions();
			this.addClickHandler();
			this.createLegend();
		},
		// Add the dataset to the chart
		addDataset(data) {
			const first = this.data[this.myself].datasets.length == 0;

			// Check if is the first dataset in the chart data 
			if (first) {
				let dataset = {
					label: [truncateStr(this.currentOrgLevel, 20)],
					data: [],
					active: [],
					backgroundColor: [],
					borderColor: [],
					borderWidth: 1,
					maxBarThickness: 150
				};
				
				// Set the chart data
				data.forEach((d) => {
					dataset.backgroundColor.push(colors.alpha.blue);
					dataset.borderColor.push(colors.plain.blue);

					this.data[this.myself].labels.push(d.org_level);
					dataset.active.push(false);
					dataset.data.push(d.click_rate);
				});

				this.data[this.myself].datasets[0] = dataset;
			} else {
				let dataset = {
					label: this.ads.getBreadcrumbFor(this.myself),
					data: this.data[this.myself].datasets[0].data.map(() => null),
					backgroundColor: this.data[this.myself].datasets[0].backgroundColor.map(() => colors.alpha.green),
					borderColor: this.data[this.myself].datasets[0].borderColor.map(() => colors.plain.green),
					borderWidth: 1,
					maxBarThickness: 150
				};

				data.forEach(d => {
					const idx = this.data[this.myself].labels.indexOf(d.org_level);
					dataset.data[idx] = d.click_rate;
				});

				this.data[this.myself].datasets[1] = dataset;
			}
			this.chartData = this.data[this.myself];
		},
		// Remove the filtered dataset 
		removeDataset() {
			if (this.data[this.myself].datasets[1]) {
				this.data[this.myself].datasets.pop();
			}
		},
		// Register the click event on the chart
		addClickHandler() {
			var canvas = this.$refs.chart.$data._chart.canvas;
			canvas.onclick = this.handleClick;
			canvas.onmousemove = this.handleHover;
		},
		// Create the custom legend to set the boxes background color
		createLegend() {
			this.$refs.chart.addPlugin({
				id: 'custom-legend',
				beforeDraw: (chart) => {
					var legendItems = chart.legend.legendItems;
					legendItems.forEach((item, index) => {
						item.fillStyle = colors.palette.dark[index % colors.palette.dark.length];
					});
				}
			});
		},
		// Set the options for the chart
		setOptions() {
			this.options = Object.assign({
				legend: {
					display: true,
					labels: {
						fontColor: colors.plain.primary,
						fontSize: 16,
						padding: 20,
					},
					onClick: this.handleLegendClick
				}
			}, BarChartsOptions);
		},
		// Highligth the selected bar of the chart
		highlightDataset(elementIndex, active) {
			const bgColor = active ? colors.alpha.blue : colors.alpha.lightBlue;
			const brColor = active ? colors.plain.blue : colors.alpha.lightBlue;

			this.data[this.myself].datasets[0].backgroundColor[elementIndex] = bgColor;
			this.data[this.myself].datasets[0].borderColor[elementIndex] = brColor;
		},
		// Handle the click event
		handleClick(evt) {
			const chart = this.$refs.chart.$data._chart;
			let activeElement = chart.getElementAtEvent(evt);

			if (activeElement.length > 0) {
				activeElement = activeElement[0];

				if (0 == activeElement._datasetIndex) {
					this.myselfClicked = true;
					const reset = this.selectedIndex[this.myself] == activeElement._index;
					this.selectedIndex[this.myself] = reset ? null : activeElement._index;

					const label = this.data[this.myself].labels[activeElement._index];
					const filter = label ? label : this.currentOrgLevel + "_" + this.ads.NotAssignedLabel;

					this.ads.setFilter(this.myself, reset ? null : filter);
				}
			}
		},
		// Handle the mouse move event on the dataset
		handleHover(evt) {
			if (this.data[this.myself]) {
				var chart = this.$refs.chart.$data._chart;
				var activeElement = chart.getElementAtEvent(evt);
				if (activeElement.length > 0) {
					activeElement = activeElement[0];
					if (activeElement._datasetIndex == 0) {
						evt.target.style.cursor = 'pointer';
						this.highlightDataset(activeElement._index, true);
					}
					this.$refs.chart.$data._chart.update();
				} else {
					evt.target.style.cursor = 'default';
					this.toggleActive();
				}
			}
		},
		// Handle the click event on the legend
		handleLegendClick(e, legendItem) {
			let index = legendItem.datasetIndex;
			let chart = this.$refs.chart.$data._chart;
			let alreadyHidden = !chart.getDatasetMeta(index).hidden ? false : chart.getDatasetMeta(index).hidden;

			chart.data.datasets.forEach((dataset, datasetindex) => {
				let meta = chart.getDatasetMeta(datasetindex);

				if (chart.data.datasets.length === 1) {
					meta.hidden = !meta.hidden;
				} else {
					meta.hidden = !alreadyHidden && meta.hidden ? !meta.hidden && !alreadyHidden : datasetindex !== index;
				}
			});

			this.$refs.chart.$data._chart.update();
		},
		// Toggle active status
		toggleActive() {
			const myselfActive = this.ads.filter[this.myself];
			let elementIndex = null;
			this.myselfClicked = false;
			
			// Check if is present a filter coming from org without label
			if (myselfActive && myselfActive.indexOf(this.ads.NotAssignedLabel) !== -1) {
				elementIndex = this.data[this.myself].labels.indexOf(null);
			} else {
				elementIndex = !myselfActive ? this.selectedIndex[this.myself] : this.data[this.myself].labels.indexOf(myselfActive);
			}

			const othersActive = this.ads.hasOtherValidFilters(this.myself);

			// Perform the toggle of the element
			this.data[this.myself].labels.forEach((label, index) => {
				this.data[this.myself].datasets[0].active[index] = (index == elementIndex) && myselfActive || (label == myselfActive);
				this.highlightDataset(index, myselfActive ? this.data[this.myself].datasets[0].active[index] : !othersActive);
			});

			this.$refs.chart.$data._chart.update();
		},
		// Remove the filter on breadcrumb click
		removeFilter(event) {
			if (event.target.classList.contains("close")) {
				this.ads.setFilter(event.target.getAttribute("filter"), null);
			}
		},
		// Select the Organizational Level clicked in the tab
		selectOrgLevel(event) {
			let selected = event.target;
			const orgLevel = selected.getAttribute("orgLevel");
			const orgIdx = selected.getAttribute("idx");
			let tabs = this.$refs.tabList.children;

			// Search and remove the active class from the active tab
			for (const tab in tabs) {
				if (tabs.hasOwnProperty(tab)) {
					const element = tabs[tab];
					if (element.className.indexOf("active") !== -1) {
						element.classList.remove("active");
					}
				}
			}

			// Update the active tab
			if (orgLevel !== this.myself) {
				selected.classList.add("active");
				this.$emit("orgChanged", orgLevel, orgIdx);
			}
		}
	}
}
</script>

<style lang="less">
#clickRateByOrgs {
	min-height: 490px;
	.card-body {
		padding-top: 0;
	}
	.card-title {
		height: 44px !important;
		margin-right: 30px;
		.title {
			padding: 0;
			margin-top: 1.25rem;
			min-width: 200px;
		}
		.filter-container {
			padding-right: 15px !important;
			margin-top: 1.25rem;
			max-width: 40% !important;
		}
	}
	.card-tabs {
		padding: 0;
	}
	.list-group-horizontal {
		.list-group-item {
			font-size: 0.9rem;
			border-bottom: none !important;
			border-left: none !important;
			border-right: none !important;
			border-top: 4px solid rgba(0, 0, 0, 0);
			border-radius: 0 !important;
			text-align: center;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.list-group-item.active,
		.list-group-item:focus {
			color: @cg-primary-dark-text-color;
			background-color: #ffffff !important;
			border-top: 4px solid #4ba1ff;
			font-weight: bold;
		}
	}
	.tab-dropdown {
		position: absolute;
		right: 15px;
		cursor: pointer;
		font-size: 1rem;
		margin-top: 1.25rem;
		.dropdown-menu {
			width: 300px;
			.dropdown-item {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
		.dropdown-toggle::after {
			display: none;
		}
		.btn-secondary {
			color: @cg-primary-dark-text-color;
			background-color: transparent;
			border: none;
			margin-right: 20px;
		}
	}
	.expand-icon,
	.compress-icon {
		position: absolute;
		right: 15px;
		width: 1rem;
		cursor: pointer;
		margin-top: 1.10rem;
	}
}

.compressed {
	.card-tabs {
		display: none;
	}
}

@media only screen and (max-width: 992px) {
	#clickRateByOrgs {
		.expand-icon,
		.compress-icon {
			display: none !important;
		}
		.tab-dropdown .btn-secondary {
			margin-right: 0 !important;
		}
	}
}
@media only screen and (max-width: 1200px) {
	#clickRateByOrgs {
		.card-title {
			.filter-container {
				max-width: 60% !important;
			}
		}
		.card-tabs {
			display: none;
		}
	}
}
</style>