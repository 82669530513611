<template>
	<b-container fluid id="analytics">
		<div class="page-content" v-if="$checkPermission('view-campaign-summary')">
			<b-row>
				<b-col cols="12">
					<b-row class="font-14 mb-3" align-v="center">
						<b-col cols="auto" class="pr-0">
							<last-update :separator="filteredOrgLevel && filteredOrgLevel.length > 0"></last-update>
						</b-col>
						<b-col class="pl-0">
							<org-filter :orgs="filteredOrgLevel"></org-filter>
						</b-col>
					</b-row>
				</b-col>
				<b-col lg="8" :class="ads.expanded ? 'col-lg-12 mb-4 tr' : 'mb-4 tr'">
					<click-rate-by-campaign :ads="ads"></click-rate-by-campaign>
				</b-col>
				<b-col lg="4" class="mb-4 tr">
					<analytics-pie :ads="ads"></analytics-pie>
				</b-col>
				<b-col :class="ads.expanded ? 'col-lg-8 mb-4 tr compressed' : 'col-lg-12 mb-4 tr'">
					<click-rate-by-orgs :ads="ads"></click-rate-by-orgs>
				</b-col>
				<b-col lg="12" class="mb-4 tr">
					<click-rate-by-template :ads="ads"></click-rate-by-template>
				</b-col>
				<b-col lg="12" class="d-sm-none d-md-none d-lg-block mb-4 tr">
					<click-rate-by-org :ads="ads"></click-rate-by-org>
				</b-col>
				<b-col lg="12" class="mb-4 tr">
					<click-rate-by-clickers :ads="ads"></click-rate-by-clickers>
				</b-col>
			</b-row>
		</div>
	</b-container>
</template>

<script>
import Vue from 'vue';

import ClickRateByCampaign from "@/components/analytics/cards/ClickRateByCampaign";
import AnalyticsPie from "@/components/analytics/cards/AnalyticsPie";
import ClickRateByOrgs from "@/components/analytics/cards/ClickRateByOrgs";
import ClickRateByOrg from "@/components/analytics/cards/ClickRateByOrg";
import ClickRateByTemplate from "@/components/analytics/cards/ClickRateByTemplate";
import ClickRateByClickers from "@/components/analytics/cards/ClickRateByClickers";
import LastUpdate from "@/components/LastUpdate";
import createAnalyticsDataSource from "@/services/analytics";
import OrgFilter from '@/components/OrgFilter.vue';

export default {
	name: "cg-analytics",
	components: {
		ClickRateByCampaign,
		AnalyticsPie,
		ClickRateByOrgs,
		ClickRateByOrg,
		ClickRateByTemplate,
		ClickRateByClickers,
		LastUpdate,
		OrgFilter
	},
	data: function () {
		return {
			ads: createAnalyticsDataSource({ log: console, bus: new Vue() }, localStorage.getItem("cg-company"), this.$account),
			lastUpdate: null,
			filteredOrgLevel: []
		};
	},
	async mounted() {
		if (this.ads) {
			let companyId = ''+this.$account.current_company;
			if(this.$account.filtered_orgs && this.$account.filtered_orgs[companyId]) {
				Object.values(this.$account.filtered_orgs[companyId]).forEach((org) => this.filteredOrgLevel.push(org));
			}
		}
	},
	methods: {}
};
</script>

<style scoped lang="less">
#analytics {
	.tr {
		transition: all 0.3s ease;
	}
}
</style>