import { truncateStr } from "@/components/utils";
import { NotAssignedLabel } from '../../../server/common/constants';

function createAnalyticsDataSource(ctx, company_id, account) {
    const companyId = company_id;
    var filter = {};
    var filterName = {};
    var defaultFilter = [];
    let self = {};
    let expanded = false;

	if(account.filtered_orgs && account.filtered_orgs[companyId]) {
		setDefaultFilters(account.filtered_orgs[companyId]);
	}

    function setFilter(id, value, displayName, skip) {
        // Prevent org charts from deleting account default filters already applied
        if(defaultFilter.indexOf(id) !== -1) return;

        if (filter[id] === value) {
            if (filter[id] == null) {
                return;
            }
            value = null;
        }
        filter[id] = value;
        filterName[id] = displayName || value;
        ctx.bus.$emit("filter_changed", id, value, skip);
    }

    function setDefaultFilters(filteredOrg) {
        Object.keys(filteredOrg).forEach((key) => {
            setFilter(key, filteredOrg[key]);
            // Avoid adding twice account default filters
            if(defaultFilter.indexOf(key) == -1) { 
                defaultFilter.push(key);
            }
        });
    }

    function getOtherFilters(widget_name) {
        let result = [];
        for (let f in filter) {
            if (f != widget_name && filter[f]) {
                result.push({ filter: f, value: filter[f], name: filterName[f] });
            }
        }
        return result;
    }
  
    function getFiltersFor(widget_name) {
        let result = {};
        for (let f in filter) {
            if ((f != widget_name || defaultFilter.indexOf(widget_name) != -1) && filter[f]) {
                result[f] = filter[f];
            }
        }
        return result;
    }

    function hasOtherFilters(widget_name) {
        return getOtherFilters(widget_name).length > 0;
    }

    function hasOtherValidFilters(widget_name) {
        let result = [];
        for (let f in filter) {
            if(defaultFilter.indexOf(f) == -1 && f != widget_name && filter[f]) {
                result.push({ filter: f, value: filter[f], name: filterName[f] });
            }
        }
        return result.length > 0;
    }

    function getBreadcrumbFor(widget_name) {
        return getOtherFilters(widget_name).filter((f) => defaultFilter.indexOf(f.filter) == -1).map((f) => {
            const filterName = f.name.indexOf(NotAssignedLabel) === -1 ? f.name : f.name.split("_" + NotAssignedLabel)[0] + " (N.A.)";
            return truncateStr(filterName, 20);
        }).join(" / ");
    }
    
    function getInteractiveBreadcrumb(widget_name) {
        return getOtherFilters(widget_name).map((f) => {
            // No breadcrumb is shown for account default filter
            if(defaultFilter.indexOf(f.filter) != -1) {
                return;
            }
            const filterName = f.name.indexOf(NotAssignedLabel) === -1 ? f.name : f.name.split("_" + NotAssignedLabel)[0] + " (N.A.)";
            return `<div title="${filterName}" role="alert" aria-live="polite" aria-atomic="true" class="alert alert-dismissible alert-info" :filter="null">
            <button type="button" aria-label="Close" class="close" filter="${f.filter}">×</button>${truncateStr(filterName, 20)}</div>`;
        }).join("");
    }

    self.ctx = "analytics";
    self.companyId = companyId;
    self.filter = filter;
    self.filterName = filterName;
    self.expanded = expanded;
    self.bus = ctx.bus;
    self.setFilter = setFilter;
    self.hasOtherValidFilters = hasOtherValidFilters;
    self.getOtherFilters = getOtherFilters;
    self.getFiltersFor = getFiltersFor;
    self.hasOtherFilters = hasOtherFilters;
    self.getBreadcrumbFor = getBreadcrumbFor;
    self.getInteractiveBreadcrumb = getInteractiveBreadcrumb;
    self.NotAssignedLabel = NotAssignedLabel;

    return self;
}

export default createAnalyticsDataSource;