<template>
	<div>
		<b-card id="clickRateByTemplate">
			<b-card-title class="mb-4">
				<div class="title" :title="title">
					{{ title }}
					<font-awesome-icon id="riskEvolutionTooltip" :icon="['fas', 'info-circle']" class="mr-1"/>
					<b-tooltip class="mt-2 mr-2" triggers="hover" right target="riskEvolutionTooltip">
						<span v-html="$t('Analytics.Tooltips.ClickRateByTemplateInfo')"></span>
					</b-tooltip>
				</div>
				<b-col class="filter-container">
					<span v-html="breadCrumb" @click="removeFilter"></span>
				</b-col>
				<b-spinner class="spinner" variant="primary" :label="$t('General.Loading')" v-if="inProgress"></b-spinner>
			</b-card-title>
			<cg-analytics-table
				:data="data"
				variant="cerulean"
				@rowClicked="filter"
				@previewClicked="preview"
				@sortChanged="sort"
				ref="table"
			></cg-analytics-table>
		</b-card>

		<cg-template-modal :template="template" :isCampaign="true" :companyData="companyData" ref="cgTemplateModal"></cg-template-modal>
	</div>
</template>

<script>
import AnalyticsTable from "@/components/tables/AnalyticsTable";
import companyService from "@/services/company.service";
import templateService from '../../../services/template.service';

export default {
	components: {
		"cg-analytics-table": AnalyticsTable
	},
	data() {
		return {
			title: this.$t('Analytics.ClickRateByTemplate'),
			data: {},
			myself: "template",
			breadCrumb: "",
			inProgress: true,
			sortBy: "click_rate",
			sortDesc: true,
			myselfClicked: false,
			template_types: [
				this.$t("Analytics.NA"),
				this.$t('Analytics.TemplateTypes.Email'),
				this.$t('Analytics.TemplateTypes.Sms')
			],
			template: null,
			companyData: { company: { company_id: this.$account.current_company } }
		};
	},
	props: {
		ads: Object
	},
	mounted() {
		this.refresh();

		this.ads.bus.$on("filter_changed", () => {
			this.breadCrumb = this.ads.getInteractiveBreadcrumb();
			if (name !== this.myself) {
				this.refresh();
			}
		});
	},
	updated() {
		if (this.data.ref_id) {
			this.toggleActive();
		}
	},
	methods: {
		async refresh() {
			let data = !this.myselfClicked ? await this.getData() : null;
			if (data) this.createTable(data);
		},
		async getData() {
			this.inProgress = true;

			const filters = this.ads.getFiltersFor(this.myself);
			const queryParams = filters ? filters : {};
			queryParams.ctx = this.ads.ctx;

			let result = this.ads.companyId ? await companyService.getAnalyticsSummary(this.ads.companyId, this.myself, queryParams) : {};

			this.inProgress = false;
			return result.data;
		},
		createTable(data) {
			var self = this;
			data.forEach(function (row) {
				row.click_rate = row.sent > 0 ? (100 * (row.clicked / row.sent)).toFixed(1) + "%" : 0 + "%";
				row.used_last = row.used_last == 1 ? self.$t('Analytics.Table.Yes') : self.$t('Analytics.Table.No');
				row.type_label = self.template_types[row.type] || self.$t("Analytics.NA");
			});

			let fields = [
				{ key: 'name', label: this.$t('Analytics.Table.Name'), sortable: true },
				{ key: 'used_last', label: this.$t('Analytics.Table.UsedLast'), sortable: true, class: 'text-center' },
				{ key: 'type_label', label: this.$t('Analytics.Type'), sortable: true, class: 'text-center' },
				{ key: 'sent', label: this.$t('Analytics.Table.Sent'), sortable: true, class: 'text-center' },
				{ key: 'clicked', label: this.$t('Analytics.Table.Clicked'), sortable: true, class: 'text-center' },
				{ key: 'click_rate', label: this.$t('Analytics.Table.ClickRate'), sortable: true, class: 'text-center' },
				{ key: 'preview', label: this.$t('Template.Preview'), sortable: false, class: 'text-center' }
			];

			this.data = {
				table_id: this.myself,
				primary_key: "template_id",
				ref_id: "click_by_template",
				items: data,
				fields: fields,
				sortBy: this.sortBy,
				sortDesc: this.sortDesc
			};
		},
		toggleActive() {
			let self = this;
			this.myselfClicked = false;
			setTimeout(function () {
				self.$refs.table.clearSelected(self.data.ref_id);
				self.$refs.table.$el.querySelectorAll("tbody tr").forEach(function (tr, index) {
					const id = tr.id.split("" + self.myself + "__row_")[1];
					if (self.ads.filter[self.myself] == id) {
						self.$refs.table.selectRow(self.data.ref_id, index);
					}
				});
			}, 100);
		},
		filter(item) {
			this.myselfClicked = true;
			this.ads.setFilter("template", item.template_id, item.name);
		},
		async preview(item) {
			
			// retrieve full template information
			let result = await templateService.getTemplateDetails(item.template_id);
			item.body = result.data.length? result.data : [{ body: "", hints: 0 }];
			item.languages = result.data.length;
			item.type = item.type_label === this.$t('Analytics.TemplateTypes.Email') ? 1 : (item.type_label === this.$t('Analytics.TemplateTypes.Sms') ? 2 : 0);

			// Show template preview
			this.template = item;
			this.$refs.cgTemplateModal.showPreviewModal(item);
		},
		sort(context) {
			this.sortBy = context.sortBy;
			this.sortDesc = context.sortDesc;
			this.toggleActive();
		},
		removeFilter(event) {
			if (event.target.classList.contains("close")) {
				this.ads.setFilter(event.target.getAttribute("filter"), null);
			}
		}
	}
}
</script>

<style lang="less">
#clickRateByTemplate {
	.b-table-sticky-header {
		max-height: 400px;
	}
}
</style>